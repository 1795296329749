<template>
  <div>
    <a-card style="margin-bottom: 20px">
      <h3>过去七天表现</h3>
      <div class="chart">
        <bar-chart
          :id="'barChart'"
          :box-style="{ width: '100', height: '300' }"
          :chart-data="barData"
        />
      </div>
    </a-card>
    <a-card>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="listData"
        :pagination="false"
        :rowKey="(record) => record.aweme_id"
        :loading="loading"
      >
        <span slot="play" slot-scope="text">
          {{ Math.floor(text/100) | changeNum  }}
        </span>
        <span slot="digg_count" slot-scope="text">
          {{ text | changeNum }}
        </span>
        <span slot="comment_count" slot-scope="text">
          {{ text | changeNum }}
        </span>
        <span slot="share_count" slot-scope="text">
          {{ text | changeNum }}
        </span>
      </a-table>
      <!-- 分页配置 -->
      <!-- <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
      >
      </base-pagination> -->
    </a-card>
  </div>
</template>
<script>
import BarChart from "./components/BarChart";
const columns = [
  {
    title: "时间",
    dataIndex: "statistics_day",
    // scopedSlots: { customRender: "awemeId" },
  },
  {
    title: "播放(百)",
    dataIndex: "play_volumec_count",
    scopedSlots: { customRender: "play" },
  },
  {
    title: "点赞",
    dataIndex: "digg_count",
    scopedSlots: { customRender: "digg_count" },
  },
  {
    title: "评论",
    dataIndex: "comment_count",
    scopedSlots: { customRender: "comment_count" },
  },
  {
    title: "转发",
    dataIndex: "share_count",
    scopedSlots: { customRender: "share_count" },
  },
];
export default {
  components: {
    BarChart,
  },
  data() {
    return {
      aweme_id: this.$route.query.id,
      barData: {
        time: [], 
        xData: ["播发量(百)", "点赞量", "评论量", "转发量"],
        yData: [],
      },
      columns,
      listData: [],
      loading:false
      // pagination: {
      //   current: 1,
      //   page_size: 10,
      //   total: 0,
      // },
    };
  },
  created(){
      this.getDealerDetail()
  },
  methods: {
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getDataList(current, pageSize);
    },
    // 获取经销商详情
    getDealerDetail() {
      this.loading =true
      this.$api.core.Polymers.dealerDetail(this.aweme_id).then((res) => {
        if (res.length){
         this.barData = this.disposeChart(res);
         this.listData = res
        }
        console.log(res,'resresrsersre')
        this.loading =false
      }).catch(()=>{
        this.loading= false
      });
    },
    // 处理E chart数据
    disposeChart(data) {
      let chartData = {
        time: [],
        xData: ["播发量(百)", "点赞量", "评论量", "转发量"],
        yData: [],
      };
      chartData.time = data.map(({ statistics_day }) => statistics_day);
      let play = data.map(({ play_volumec_count }) => Math.floor(play_volumec_count/100));
      let digg = data.map(({digg_count}) => digg_count);
      let comment = data.map(({comment_count}) => comment_count);
      let share = data.map(({share_count}) =>share_count);
      chartData.yData =[play,digg,comment,share]
      chartData.time = chartData.time.map((item) => {
        return item.slice(5, -8);
      });
      return chartData;
    },
  },
};
</script>
<style lang="less" scoped>
h3 {
  color: #4a58f3;
  border-left: 4px solid #4a58f3;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
}
</style>