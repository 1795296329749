<template>
  <div :id="id" :style="styleObject"></div>
</template>
<script>
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import resize from "@/components/mixins/resize";

export default {
  mixins: [resize],
  props: {
    id: {
      type: String,
      default: "bar_chart",
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: "100", height: "300px" };
      },
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          xAxisData: [],
          yAxisData: [],
        };
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    styleObject: function () {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";

        obj[attr] =
          unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    },
  },
  watch: {
    chartData: function (val) {
      this.initChart();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    // this.charts.dispose()// 销毁实例
    this.charts = null;
  },
  methods: {
    initChart() {
      console.log("this.$route.params.hot_level", this.$route.query.hot_level);
      this.chart = echarts.init(document.getElementById(this.id));
      let color = [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ];

      const series = [];
      const arr = this.chartData.xData;
      for (const key in arr) {
        series.push({
          name: arr[key],
          color: color[key],
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: this.chartData.yData[key],
        });
      }
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: this.chartData.xData,
        },
        toolbox: {
          show: false,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack", "tiled"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: "category",
            // axisTick: { show: false },
            data: this.chartData.time,
            // minorTick:{
            //     show:true
            // }
          },
        ],
        yAxis: [
            
          {
            type: "value",
            axisTick: { show: false },
            axisLine: { show:false }
          },
        ],
        series,
      });
    },
  },
};
</script> 